<template>
  <div v-if="columns && successList && failureList">
    <a-modal
      title="确认信息"
      :closable="false"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="850"
      :okText="tabIndex == 1 ? '导入正确数据' : isShowErrorDownBtn != 1 ? '点击下载':''"
    >
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="正确数据"></a-tab-pane>
        <a-tab-pane key="2" tab="错误数据"></a-tab-pane>
      </a-tabs>
      <a-table
        :scroll="{ x: 850 }"
        class="table-template"
        :rowKey="(item, index) => index"
        :columns="columns"
        :data-source="tabIndex == 1 ? successList : failureList"
        @change="onPage"
        :pagination="{
          hideOnSinglePage: true,
          total: tabIndex == 1 ? successList.length : failureList.length,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
      </a-table>
      <p class="title" v-show="tabIndex == 2 && isShowErrorDownBtn != 1">可下载excel查看失败数据。</p>
    </a-modal>
  </div>
</template>

<script>
// const columns = [
//   {
//     title: "订单编号",
//     align:'center',
//     dataIndex: "code1"
//   },
//   {
//     title: "商品信息",
//     align:'center',
//     dataIndex: "code2"
//   },
//   {
//     title: "订单类型",
//     align:'center',
//     dataIndex: "code3"
//   },
//   {
//     title: "用户账号",
//     align:'center',
//     dataIndex: "code4"
//   },
//   {
//     title: "订单状态",
//     align:'center',
//     dataIndex: "code5"
//   },
//   {
//     title: "实付款",
//     align:'center',
//     dataIndex: "code6"
//   },
//   {
//     title: "退款状态",
//     align:'center',
//     fixed:'right',
//     dataIndex: "code8"
//   }
// ];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值  isShowErrorDownBtn: 是否显示下载错误数据按钮
  props: ["columns", "visible", "handleCancel", "successList", "failureList", "isShowErrorDownBtn"],
  // 数据对象
  data() {
    return {
      confirmLoading: false,
      // total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      tabIndex: 1,
    };
  },
  // 事件处理器
  methods: {
    // tab切换
    callback(e) {
      this.tabIndex = e;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      if (this.tabIndex == 2) {
        if(!this.failureList.length){
          return this.$message.error('暂无错误数据')
        }
        this.$parent.downloadExcel();
      } else {
        if(!this.successList.length){
          return this.$message.error('没有可导入的数据')
        }
        this.confirmLoading = true;
        this.$parent.importSuccessList();
        setTimeout(()=>{
          this.confirmLoading = false;
        },2000)
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-header {
  border: 0;
  text-align: center;
}
/deep/.ant-modal-footer {
  border: 0;
  padding-bottom: 30px;
}
.title {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
</style>
