<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 证书管理 </template>
      <template #input>
        <a-input placeholder="请输入手机号" v-model="userMobile" allowClear />
        <a-input placeholder="请输入姓名" v-model="userName" allowClear />
        <a-select placeholder="请选择证书名称" v-model="pictureId" showSearch allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="item in templateList" :key="item.tempId" :value="item.tempId">
            <a-tooltip placement="bottom" :title="item.certifName">
              {{ item.certifName }}
            </a-tooltip>
          </a-select-option>
        </a-select>
        <a-range-picker @change="onSelectTime" :placeholder="['开始日期', '结束日期']" format="YYYY-MM-DD" />
        <a-button type="primary" class="input" @click="onSearch()" v-hasPermi="['user:certif:list']">搜索</a-button>
        <a-button class="btn" @click="addNewData()" v-hasPermi="['user:certif:add']">新增人员</a-button>
        <a-button class="btn" v-hasPermi="['certif:data:generation']" @click="onShowTime(1)">下载证书二维码</a-button>
        <a-button class="btn" @click="onDownLoadTemp()">下载模版</a-button>
        <a-upload class="btn" name="videoFileId" :beforeUpload="beforeUpload" :showUploadList="false" :customRequest="customRequest"
          :loading="uploadLoading">
          <a-button>批量生成</a-button>
        </a-upload>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table class="table-template" :rowKey="(item) => item.certifId" :columns="columns" :loading="loading"
        :data-source="tableData" :locale="{ emptyText: '暂无数据' }" @change="onPage" :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">
        <!-- 序号 -->
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 证书类型 -->
        <template slot="type" slot-scope="item">
          <div style="text-align: center">
            {{ item | zstype }}
          </div>
        </template>
        <!-- 操作 -->
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="deleteData(item)" v-hasPermi="['user:certif:delete']">作废</a>
            <a @click="goDetail(item)" v-hasPermi="['user:certif:update']">
              | 重新生成</a>
            <a @click="showCertImage(item.picture)">
              | 查看</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 数据弹窗 -->
    <LeadingIn :columns="columnsss" :isShowErrorDownBtn="1" :successList="successList" :failureList="failureList"
      :visible="importVisible" :handleCancel="modelhandleCancel" />

    <!-- 弹框选择证书模版 -->
    <a-modal v-model="modelVisible" :closable="true" title="选择证书模版" :confirmLoading="confirmLoading"
      @ok="importSuccessListFunc">
      <a-select class="model-ant-select" v-model="tempId" placeholder="请选择证书模版">
        <a-select-option :value="item.tempId" v-for="item in templateList" :key="item.tempId"> {{ item.certifName }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
    scopedSlots: { customRender: "idcard" },
  },
  {
    title: "证书类型",
    align: "center",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifName",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证时间",
    align: "center",
    dataIndex: "getcertifTime",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证日期",
    align: "center",
    dataIndex: "getcertifTime",
  },
  {
    title: "出生日期",
    align: "center",
    dataIndex: "birthday",
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      uploadLoading: false,
      userMobile: "", //手机号
      userName: "", //姓名
      courseId: undefined, // 关联课程
      pictureId: undefined, // 关联模板
      columns,
      columnsss,
      total: 1,
      pageNum: 1,
      pageSize: 10,
      tableData: [],
      pictureDropDownList: [], // 证书模板列表
      queryParams: {
        // 搜索请求参数
        userName: "", // 姓名
        mobile: "", // 手机号
        startDate: "", // 开始时间
        endDate: "", // 结束时间
        tempId: "", // 证书tempid
        pageNum: 1,
        pageSize: 10,
      },
      selectedSignUpRowKeys: [],
      listQueryParams: {
        // 模板证书请求参数
        // type: 1, // 模板类型 1.考试模板 2.课程模板
        pageNum: 1,
        pageSize: 100,
      },
      templateList: [], // 模版证书列表

      // 导入弹框
      modelVisible: false, // 弹框选择证书模版
      tempId: undefined, // 导入证书模版id
      confirmLoading: false, // 弹框loading
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 搜索
    onSearch() {
      this.pageNum = 1;
      this.getData();
    },
    // 跳转详情页
    goDetail(item) {
      this.$router.push({
        path: "/admin/BasicConfig/CertificateManagerDetail",
        query: { certifId: item.certifId, operation: item.type },
      });
    },
    // 获取证书列表
    getPictureDropDownList() {
      this.$ajax({
        url: "/hxclass-management/certiftemp/getPictureDropDownList",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.pictureDropDownList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 获取数据
    getData() {
      this.loading = true;
      this.queryParams.userName = this.userName;
      this.queryParams.mobile = this.userMobile;
      this.queryParams.tempId = this.pictureId;
      this.queryParams.pageNum = this.pageNum;
      this.queryParams.pageSize = this.pageSize;
      this.$ajax({
        url: "/hxclass-management/user-certif/getUserCertifList",
        method: "get",
        params: this.queryParams,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    // 批量生成-上传前钩子
    beforeUpload(file) {
      var reg = /\.(xlsx|xls)(\?.*)?$/;
      if (!reg.test(file.name)) {
        this.$message.warning("仅支持：xlsx、xls格式文件");
        return false;
      }

      if (this.uploadLoading) {
        this.$message.warning("附件正在上传中，请稍后重试");
        return false;
      }
    },
    // 附件上传 - 单文件
    customRequest(fileData) {
      this.uploadLoading = true;
      this.$ajax({
        method: "POST",
        url: "/hxclass-management/user-certif/userCertifExcel",
        headers: [{ type: "file" }],
        params: {
          file: fileData.file,
          name: "file",
        },
      })
        .then((res) => {
          this.uploadLoading = false;
          this.successList = res.data.correctList;
          this.failureList = res.data.errtList;
          this.importVisible = true;
        })
        .catch((error) => {
          this.uploadLoading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    modelhandleCancel() {
      this.importVisible = false;
    },

    // 下载错误excel
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      // window.open(
      //   this.$config.target +
      //     "/hxclass-management/certifData/exportErrorData?json=" +
      //     encodeURIComponent(JSON.stringify(this.failureList))
      // );
    },
    importSuccessList() {
      if (!this.successList.length) {
        this.$message.error('无正确数据！')
        return;
      }
      this.modelVisible = true; // 打开选择证书模版弹框
    },
    // 导入正确数据
    importSuccessListFunc() {
      if (!this.tempId) {
        return this.$message.warning('请选择证书模版！')
      }
      this.confirmLoading = true;
      this.$ajax({
        url: "/hxclass-management/user-certif/excelAddUserCertif",
        method: "POST",
        params: {
          userCertifAddDTOS: this.successList,
          tempId: this.tempId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.tempId = undefined;
          this.importVisible = false;
          this.modelVisible = false;
        } else {
          this.$message.error(res.message);
        }
        this.confirmLoading = false;
      });
    },
    // 删除模板
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确认删除该证书?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url:
                "/hxclass-management/user-certif/manageCertifInvalid/" +
                item.certifId,
              method: "PUT",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() { },
      });
    },
    // 选择时间
    onSelectTime(date, dateString) {
      this.queryParams.startDate = dateString[0]; // 开始日期
      this.queryParams.endDate = dateString[1]; // 结束日期
    },
    // 选择数据
    select(e) {
      console.log(e, 'xuanze shujv')
      this.selectedSignUpRowKeys = e;
    },
    addNewData() {
      this.$router.push("/admin/BasicConfig/CertificateManagerAdd");
    },
    // 查询证书模板列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/certiftemp/getSelection",
        params: this.listQueryParams,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.templateList = res.data.records;
          } else {
            this.templateList = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    // 查看证书图片
    showCertImage(e) {
      window.open(e);
    },
    onShowTime() {
      this.queryParams.tempId = this.pictureId;

      if (this.queryParams.tempId == "" || this.queryParams.tempId == undefined) {
        this.$message.error("请选择证书模版，筛选后再进行下载");
        return;
      }

      this.loading = true;
      var submitUrl = "/hxclass-management/user-certif/QRcodeDownload?tempId=" + this.queryParams.tempId;
      submitUrl += this.endDate == undefined ? "" : "&endDate=" + this.endDate;
      submitUrl += this.userName == "" ? "" : "&userName=" + this.userName;
      submitUrl += this.userMobile == "" ? "" : "&mobile=" + this.userMobile;
      submitUrl += this.startDate == undefined ? "" : "&startDate=" + this.startDate;
      this.$ajax({
        method: "post",
        url: submitUrl,

        // url:
        //   "/hxclass-management/user-certif/QRcodeDownload?tempId=11111",
      })
        .then((res) => {
          this.loading = false;
          // console.log("========"+res.base64Zip);
          this.downloadBase64Zip(res.base64Zip);
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    onDownLoadTemp() {
      window.open("https://cos.hxclass.cn//prod/template/certificate/%E7%94%A8%E6%88%B7%E8%AF%81%E4%B9%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx");
    },
    // 下载zip压缩包
    downloadBase64Zip(base64Data, filename = 'certManager.zip') {
      // atob先解码base64数据
      const raw = window.atob(base64Data);
      // 获取解码后的字符串长度
      const rawLength = raw.length;
      // 初始化一个 8 位无符号整型数组
      const uInt8Array = new Uint8Array(rawLength);
      // 将每个解码字符转换为unicode编码再存入数组中
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      // 通过blob格式将数组转换成你想要的类型文件，第二个是类型
      const blob = new Blob([uInt8Array]);
      // 创建一个a标签在页面中实现下载
      const elink = document.createElement("a");
      elink.download = filename;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
    }
  },
  // 生命周期-实例创建完成后调用
  created() { },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getData();
    // this.getPictureDropDownList();
    this.getManageList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
  filters: {
    zstype(e) {
      // 1.中康协认证证书 2.课程证书 3.阶段证书 4.个人证书 5.CEU课程证书 6、自研课程证书
      switch (e) {
        case 1:
          return "中康协认证证书";
        case 2:
          return "课程证书";
        case 3:
          return "阶段证书";
        case 4:
          return "个人证书";
        case 5:
          return "CEU课程证书";
        case 6:
          return "自研课程证书";

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pictrue {
  max-width: 100px;
}

.flex-div {
  margin-top: 20px;
  display: flex;
  justify-content: right;

  .btn {
    margin: 0 15px;
  }
}

.btn {
  margin-right: 24px;
  margin-bottom: 10px;
}

.model-ant-select {
  width: 100%;
}
</style>